import { buscarHoldings, buscarClientes } from '../../../produto/common/resources/planejamento/planejamento-acao-cadastro';

export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      anoFiscalResource: this.$api.anoFiscal(this.$resource),
      extensaoResource: this.$api.extensao(this.$resource),
      tipoInvestimentoResource: this.$api.tipoInvestimento(this.$resource),
      centroCustoResource: this.$api.centroCusto(this.$resource),
      clienteResource: this.$api.cliente(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      tipoBeneficioResource: this.$api.tipoBeneficio(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      usuariosResource: this.$api.usuario(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'cod_planejamento_massivo',
            nomCampoId: 'id_acao_previa',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'cod_acao_tatica',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$t('status_entidade.em_cadastro'),
                  valor: 'EM_CADASTRO',
                },
                {
                  texto: this.$t('status_entidade.aguardando_aprovacao'),
                  valor: 'AGUARDANDO_APROVACAO',
                },
                {
                  texto: this.$tc('status_entidade.aprovado', 1),
                  valor: 'APROVADO',
                },
                {
                  texto: this.$tc('status_entidade.reprovado', 1),
                  valor: 'REPROVADO',
                },
                {
                  texto: this.$t('status_entidade.em_analise'),
                  valor: 'EM_ANALISE',
                },
                {
                  texto: this.$t('status_entidade.cancelado'),
                  valor: 'CANCELADO',
                },
              ],
            },
          },
          {
            labelCampo: 'hierarquia',
            nomCampoId: 'id_hierarquia1',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  idNivelExtensao: 8,
                  indiceRecursao: 2,
                  ...this.$refs.container.getValoresCamposPadrao(),
                };
                return this.extensaoResource.listarAtivos(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'tipo_pagamento',
            nomCampoId: 'id_tipo_pagamento',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.tipoBeneficioResource
                .listarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'tipo_verba',
            nomCampoId: 'id_tipo_verba',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (filter) => this.orcamentoTipoVerbaResource.buscarPorVisao({ filter }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource
                .buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              dependentes: ['usuario'],
            },
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarDivisoesPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'unidadenegocio',
            nomCampoId: 'id_unidadenegocio',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarUnidadesNegocioPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'usuario',
            nomCampoId: 'id_usuario_criacao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              dependencia: ['divisao'],
            },
            async: {
              fetchFunction: () => {
                const divisao = this.filtrosPlanejamentoAcao.divisao || [];
                const idsDivisoes = divisao.map((p) => p.id);
                return this.usuariosResource.buscarUsuarios({
                  idsDivisoes,
                });
              },
              itemValue: 'id',
              itemText: 'nomeSobrenome',
            },
          },
          {
            labelCampo: 'ano',
            nomCampoId: 'ano',
            tipoCampo: 'LISTA',
            desAtributos: {
              dependentes: ['mes'],
            },
            async: {
              fetchFunction: (ano) => this.anoFiscalResource.listarAnosDisponiveis({ ano }),
              itemValue: 'ano',
              itemText: 'ano',
            },
            events: {
              change: (anoSelecionado) => {
                this.filtrosOrcamento.ano = anoSelecionado;
              },
            },
          },
          {
            labelCampo: 'periodo_planejamento',
            nomCampoId: 'id_periodo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              dependentes: ['periodo_orcamentario'],
            },
            async: {
              fetchFunction: (autocomplete) => this.anoFiscalResource.buscar({ autocomplete }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'cliente',
            nomCampoId: 'id_cliente',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                };
                return buscarClientes(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'holding',
            nomCampoId: 'id_holding',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            filter: () => true,
            async: {
              fetchFunction: (autocomplete) => buscarHoldings({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
        ],
      },
    };
  },
};
